// ** Third Party Components
import styled from "styled-components";

// ** Custom Components
import Block from "components/layout/Block";

// #####################################################

export const StyledMediaTypeContainer = styled(Block)`
	position: relative;
	width: 100%;
`;

// #####################################################
