// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledLiveContainer = styled("div")`
	position: absolute;
	top: 8px;
	right: 8px;
	padding: 3px 5px;
	font-weight: bold;
	font-size: 0.8rem;
	line-height: 10px;
	border-radius: ${({ theme: { borderRadius } }) => borderRadius.card};
	background-color: ${({ theme: { colors } }) => colors.red};
	color: ${({ theme: { colors } }) => colors.whiteAlways};
`;
