// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const StyledMediaContainer = styled.div`
	position: relative;
	background-color: ${({ theme: { isDarkMode } }) =>
		isDarkMode
			? "var(--dz-ref-color-gray300)"
			: "var(--dz-ref-color-gray100)"};
	display: flex;
	flex: 1 1 auto;
	cursor: pointer;

	${({ maxWidth }) =>
		maxWidth &&
		css`
			max-width: min(100%, ${maxWidth});
		`}

	${({ isCover }) =>
		isCover &&
		css`
			width: 100%;
			height: 100%;
		`}

		${({ customBackgroundColor }) =>
		customBackgroundColor &&
		css`
			background-color: ${customBackgroundColor};
		`}
`;

// #####################################################
