// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Components
import Block from "components/layout/Block";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledTextWrapper = styled(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	({ removePaddingOnMobile, ...props }) => <Block {...props} />
)`
	background-color: ${({ theme: { colors } }) => colors.whiteTrue};

	border-radius: ${({ theme: { borderRadius } }) => borderRadius.element};
	margin: 0 auto;
	margin-left: 0;
	overflow-wrap: anywhere;
	@media (max-width: ${breakpoints.phone - 1}px) {
		border-radius: 0;
		padding: 0 1rem;

		${({ removePaddingOnMobile }) =>
			removePaddingOnMobile &&
			css`
				padding: 0;
			`}
	}
`;

// #####################################################
