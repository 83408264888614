import { useMemo } from "react";

export const useCalculateMediaItemPadding = ({
	withRatio_4_3 = false,
	isSquared = false,
	height,
	width,
}) => {
	const padding = useMemo(() => {
		if (withRatio_4_3 || isSquared) {
			if (height === width || isSquared) {
				return 100;
			} else if (height > width) {
				let heightFinal = height;
				const oryginalRatio = height / width;
				if (oryginalRatio > 16 / 9) {
					heightFinal = width * (16 / 9);
				}
				const ratio = 4 / 3;

				if (heightFinal / width > ratio) {
					return Math.floor(ratio * 100);
				}
				return Math.floor((heightFinal / width) * 100);
			} else {
				let widthFinal = width;
				const oryginalRatio = width / height;
				if (oryginalRatio > 16 / 9) {
					widthFinal = height * (16 / 9);
				}
				const ratio = 3 / 4;

				if (height / widthFinal < ratio) {
					return Math.floor(ratio * 100);
				}
				return Math.floor((height / widthFinal) * 100);
			}
		} else {
			const ratio = width / height;
			return 100 / ratio;
		}
	}, [width, height, isSquared]);

	return padding;
};
