// ** Third Party Components
import styled, { css } from "styled-components";

// ** Styled components
import { StyledImage } from "./Image.styled";

// #####################################################

export const StyledMediaWrapper = styled.div.attrs((props) => ({
	padding: props.padding,
	isCover: props.isCover,
	zoom: props.zoom,
}))`
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;

	${({ padding, isFilling, isCover }) =>
		padding &&
		css`
			width: 100%;
			padding-top: ${padding}%;

			${StyledImage} {
				${isFilling &&
				!isCover &&
				css`
					object-fit: fill;
				`}

				width: 100%;
				height: 100%;
			}
		`}

	${({ isCover }) =>
		isCover &&
		css`
			width: 100%;
			height: 100%;
		`}

		${({ zoom }) =>
		zoom &&
		css`
			&:hover,
			&:focus {
				img {
					transform: scale(1.05);
				}
			}

			img {
				transition: transform 100ms;
				transform: scale(1);
			}
		`}
`;

// #####################################################
