// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Components
import { IconContainer } from "components/global/Icon";

// #####################################################

export const StyledMediaIcon = styled.div`
	position: absolute;
	display: flex;
	margin: ${({ m }) => m}px;
	right: 0;
	top: 0;
	pointer-events: none;

	${({ relative }) =>
		relative &&
		css`
			position: relative;
		`}

	${IconContainer} {
		display: block;
		background-color: rgba(38, 38, 38, 0.6);
		border-radius: 100%;
		position: relative;
		padding: ${({ p, h }) => p + (h / 2 || 0)}px
			${({ p, w }) => p + (w / 2 || 0)}px
			${({ p, h }) => p + (h / 2 || 0)}px
			${({ p, w }) => p + (w / 2 || 0)}px;
	}

	svg {
		position: absolute;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
		/* left: calc(50% - ${({ w }) => w / 2 || 0}px + ${({ ml }) =>
			ml || 0}px);
		top: calc(50% - ${({ h }) => h / 2 || 0}px); */
		width: ${({ w }) => w || 0}px;
		height: ${({ h }) => h || 0}px;
	}

	svg > path {
		fill: ${({ white }) => (white ? "white" : "whitesmoke")};
	}
`;

// #####################################################
