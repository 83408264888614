// ** Third Party Components
import styled from "styled-components";

// ** Custom Components
import Link from "components/layout/Link";

// #####################################################

export const StyledMediaTypeLink = styled(Link)`
	display: flex;
	justify-content: center;
	flex: 1;
`;

// #####################################################
