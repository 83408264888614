// ** Third Party Components
import styled, { css } from "styled-components";

// **Custom Components
import Block from "components/layout/Block";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledMediaTypeContainerFull = styled(Block)`
	background-color: ${({ theme: { colors } }) => colors.whiteTrue};
	position: relative;
	padding: 0;

	@media (max-width: ${breakpoints.phone - 1}px) {
		padding: 0;

		${({ removePaddingOnMobile }) =>
			removePaddingOnMobile &&
			css`
				margin-left: -1rem;
				margin-right: -1rem;
			`}
	}
`;

// #####################################################
