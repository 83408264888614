// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const StyledImage = styled.img`
	display: flex;
	max-height: 100%;
	max-width: 100%;

	${({ isFilling }) =>
		isFilling &&
		css`
			min-height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		`};

	${({ isBlurred }) =>
		isBlurred &&
		css`
			filter: blur(7px);
			filter: progid:DXImageTransform.Microsoft.Blur(pixelradius='7', shadowopacity='0.0');
			-ms-filter: blur(7px);
			-webkit-filter: blur(7px);
		`}

	${({ zoom }) =>
		zoom &&
		css`
			transition: transform 100ms;
			transform: scale(1);

			&:hover,
			&:focus {
				transform: scale(1.05);
			}
		`}

	${({ isCover }) =>
		isCover &&
		css`
			object-fit: cover;
			min-height: 100%;
		`}

		${({ isContain }) =>
		isContain &&
		css`
			object-fit: contain;
			min-height: 100%;
		`}

		${({ $customBorder }) =>
		$customBorder &&
		css`
			border-radius: 1.25rem;
			box-sizing: border-box;
			border: ${$customBorder};
		`}
`;

// #####################################################
