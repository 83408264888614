// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const StyledImageCover = styled.span`
	display: flex;

	background-image: url(${({ src }) => src});
	background-size: cover;
	background-repeat: no-repeat;

	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;

	${({ zoom }) =>
		zoom &&
		css`
			transition: transform 100ms;
			transform: scale(1);

			&:hover,
			&:focus {
				transform: scale(1.05);
			}
		`}

	${({ isBlurred }) =>
		isBlurred &&
		css`
			filter: blur(7px);
			filter: progid:DXImageTransform.Microsoft.Blur(pixelradius='7', shadowopacity='0.0');
			-ms-filter: blur(7px);
			-webkit-filter: blur(7px);
		`}
`;

// #####################################################
