// ** Third Party Components
import styled, { css } from "styled-components";

// #####################################################

export const StyledVideo = styled.video`
	display: flex;
	max-height: 100%;
	max-width: 100%;

	${({ isFilling }) =>
		isFilling &&
		css`
			object-fit: fill;
			min-width: 100%;
			min-height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
		`};

	${({ isBlurred }) =>
		isBlurred &&
		css`
			filter: blur(7px);
			filter: progid:DXImageTransform.Microsoft.Blur(pixelradius='7', shadowopacity='0.0');
			-ms-filter: blur(7px);
			-webkit-filter: blur(7px);
		`}

	${({ isCover }) =>
		isCover &&
		css`
			object-fit: cover;
		`}
`;

// #####################################################
