// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledLoadMoreItemButton = styled("button").attrs({
	role: "button",
})`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	border: 2px solid ${({ theme: { colors } }) => colors.whiteTrue};
	background-color: transparent;

	&:hover {
		background-color: ${({ white }) =>
			white ? "rgba(0, 0, 0, 0.1)" : "rgba(255, 255, 255, 0.1)"};
	}
	&:active {
		background-color: ${({ white }) =>
			white ? "rgba(0, 0, 0, 0.1)" : "rgba(255, 255, 255, 0.1)"};
	}
`;

// #####################################################
