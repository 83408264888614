// ** Third Party Components
import styled, { css } from "styled-components";

// **Custom Components
import Block from "components/layout/Block";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledMediaTypePaddingContainerMobile = styled(Block)`
	overflow-wrap: anywhere;

	@media (max-width: ${breakpoints.phone - 1}px) {
		padding: 0 1rem;

		${({ marginTopMobile }) =>
			marginTopMobile &&
			css`
				margin-top: ${marginTopMobile}rem;
			`}

		${({ noData }) =>
			noData &&
			css`
				padding: 0;
			`}

		${({ $noPaddingAndMarginXInMobile }) =>
			$noPaddingAndMarginXInMobile &&
			css`
				padding-left: 0;
				padding-right: 0;
			`}
	}
`;

// #####################################################
